<template>
  <div class="navBar">
    <div class="logo">
      <img src="@/assets/img/HOUIT-logo.png" alt="hotel.logo" />
    </div>
    <div class="orderIcon center">
      <van-badge v-if="orderLength > 0" :content="orderLength">
        <img src="@/assets/img/order-white.png" @click="goMyOrder" alt="" />
      </van-badge>
      <img
        v-else
        src="@/assets/img/order-white.png"
        @click="goMyOrder"
        alt=""
        style="top: 0; right: 0; left: 0; bottom: 0; margin: auto"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    goMyOrder() {
      this.$emit("goMyOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
.navBar {
  height: 88px;
  width: 100%;
  background: url(../../assets/img/houit-bg.jpg) no-repeat center/101% 101%;
  // background-size: cover;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .logo {
    position: absolute;
    height: 88px;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    img {
      width: auto;
      height: 88px;
    }
  }

  .orderIcon {
    width: 40px;
    height: 36px;
    border-radius: 4px;
    background: #222744;
    position: absolute;
    right: 15px;
    top: 6px;
    z-index: 10;
    cursor: pointer;

    /deep/.van-badge__wrapper {
      width: 20px;
      height: 20px;
      position: relative;

      .van-badge.van-badge--fixed {
        z-index: 2;
      }
    }
    img {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
