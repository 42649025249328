<template>
  <div
    @click="$emit('showFoodInfo', food)"
    class="item"
    :class="[
      index == item.mainDishList.length - 1 && 'no-border',
      food.oosStatus && 'oos',
    ]"
  >
    <div
      v-if="food.image"
      @click.stop="preview(food.image)"
      style="margin-right: 10px"
    >
      <img :src="food.image" style="width: 100px; height: 100px" />
    </div>
    <div style="width: 100%">
      <div class="name">
        <span class="word-break">{{ food.name }}</span>
      </div>
      <div class="price">
        <span class="word-break"> &nbsp;${{ food.fullPrice }} </span>
      </div>
      <div class="name" style="margin: 5px 0">
        <div
          v-if="
            food.type && food.type.split(',').indexOf('GF (Gluten Free)') != -1
          "
          style="position: relative"
          @click.stop="$emit('showIconInfo')"
        >
          <img class="icon" src="@/assets/img/1.png" />
          <img class="tip" src="@/assets/img/提示.png" />
        </div>
        <div
          style="position: relative"
          v-if="
            food.type && food.type.split(',').indexOf('DF (Dairy Free)') != -1
          "
          @click.stop="$emit('showIconInfo')"
        >
          <img class="icon" src="@/assets/img/2.png" />
          <img class="tip" src="@/assets/img/提示.png" />
        </div>
        <div
          style="position: relative"
          v-if="food.type && food.type.split(',').indexOf('VG (Vegan)') != -1"
          @click.stop="$emit('showIconInfo')"
        >
          <img class="icon" src="@/assets/img/3.png" />
          <img class="tip" src="@/assets/img/提示.png" />
        </div>
        <div
          style="position: relative"
          v-if="
            food.type && food.type.split(',').indexOf('V (Vegetarian)') != -1
          "
          @click.stop="$emit('showIconInfo')"
        >
          <img class="icon" src="@/assets/img/4.png" />
          <img class="tip" src="@/assets/img/提示.png" />
        </div>
        <div
          style="position: relative"
          v-if="
            food.type && food.type.split(',').indexOf('NF (Nuts Free)') != -1
          "
          @click.stop="$emit('showIconInfo')"
        >
          <img class="icon" src="@/assets/img/5.png" />
          <img class="tip" src="@/assets/img/提示.png" />
        </div>
        <div
          style="position: relative"
          v-if="
            food.type && food.type.split(',').indexOf('N (Contain Nuts)') != -1
          "
          @click.stop="$emit('showIconInfo')"
        >
          <img class="icon" src="@/assets/img/6.png" />
          <img class="tip" src="@/assets/img/提示.png" />
        </div>
      </div>
      <div class="info">
        <span
          class="word-break"
          v-if="hotelInno === 'houit' || hotelInno === 'HOUIT'"
        >
          <div v-html="food.description"></div>
        </span>
        <span class="word-break" v-else>
          {{ food.description }}
        </span>
      </div>
      <div class="add" v-if="addFoodBtn">
        <template v-if="!food.oosStatus">
          <van-badge v-if="food.num > 0" :content="food.num">
            <van-icon name="add" size="15pt" />
          </van-badge>
          <van-icon v-else name="add" size="15pt" />
        </template>
        <div v-else>
          <span style="font-weight: 550">Sold Out &nbsp;&nbsp;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    food: {
      type: Object,
      default: () => {
        return {};
      },
    },
    addFoodBtn: {
      type: Boolean,
      default: true,
    },
    index: Number,
  },
  data() {
    return {
      hotelInno: localStorage.getItem("hotelName"),
    };
  },
  methods: {
    preview(image) {
      ImagePreview({ images: [image], closeable: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  padding: 10pt 0;
  display: flex;

  .name {
    font-size: 11pt;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #2b2e36;
    display: flex;
    align-items: center;
    width: 80%;

    .icon {
      width: 15pt;
      height: 15pt;
      background-repeat: no-repeat;
      margin-right: 5.5pt;
    }

    .tip {
      position: absolute;
      top: -30%;
      right: 0%;
      width: 12pt;
    }
  }

  .price {
    font-size: 10pt;
    font-family: ArialNarrow;
    color: #2b2e36;
    padding-bottom: 2pt;
  }

  .info {
    font-size: 10pt;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #636464;
  }

  .add {
    position: absolute;
    right: 0;
    top: 10pt;
    color: $primary-color;

    img {
      width: 15pt;
      height: 15pt;
      background-repeat: no-repeat;
    }

    /deep/.van-icon {
      color: $primary-color;
    }
  }

  .scan-small {
    position: absolute;
    right: 0;
    top: 10pt;
    color: $primary-color;
    display: flex;
    align-items: center;

    /deep/.van-icon {
      color: $primary-color;
    }
  }
}

.no-border {
  border: 0pt !important;
}

.oos {
  background-color: #e8e8e8;
}
</style>
